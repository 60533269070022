<template>
  <div class="av-swiper">
    <div v-swiper:[id]="swiperOptions">
      <div class="swiper-wrapper">
        <slot />
      </div>
    </div>
    <SliderButtonRound :id="buttonIdNext" class="av-swiper-button av-swiper-button--next" />
    <SliderButtonRound :id="buttonIdPrev" class="av-swiper-button av-swiper-button--prev" />
  </div>
</template>

<script>
import SliderButtonRound from '@/components/ui/button/slider-button-round'

export default {
  components: {
    SliderButtonRound,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    swiperOptions() {
      return {
        ...this.options,
        navigation: {
          nextEl: `#${this.buttonIdNext}`,
          prevEl: `#${this.buttonIdPrev}`,
        },
      }
    },
    buttonIdNext() {
      return `${this.id}-button-next`
    },
    buttonIdPrev() {
      return `${this.id}-button-prev`
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.av-swiper {
  position: relative;

  .swiper-wrapper,
  .swiper-container {
    width: 100%;
  }

  &-button {
    display: none;

    @media screen and (min-width: $screen-width-767) {
      position: absolute;
      display: block;

      svg {
        position: relative;
        top: 3px;
      }
    }

    &--prev {
      left: 10px;

      @media screen and (min-width: $screen-width-1220) {
        left: -60px;
      }

      svg {
        transform: rotate(180deg);
        left: -1px;
      }
    }

    &--next {
      right: 10px;

      @media screen and (min-width: $screen-width-1220) {
        right: -60px;
      }

      svg {
        left: 1px;
      }
    }
  }
}
</style>
