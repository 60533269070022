// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/svg/icon-arrow-down.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select{width:100%}.select select{background-color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:right 15px center;background-repeat:no-repeat;background-size:10px 6px;border-radius:3px;font-family:\"PTSans\",\"Arial\",\"Helvetica\",sans-serif;font-size:1.111rem;height:auto;line-height:100%;min-width:170px;padding:1rem 40px 1rem 1rem;position:relative;width:100%}@media (min-width:767px){.select select{padding:.7rem 40px .7rem .7rem}}.select select:-moz-focusring,.select__tag:-moz-focusring{color:transparent;text-shadow:0 0 0 #000}.select select::-ms-expand,.select__tag::-ms-expand{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
