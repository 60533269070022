<template>
  <article class="background-teaser">
    <SpunqImage
      v-if="article.attributes.teaser.image"
      :image="article.attributes.teaser.image"
      :width="imageWidth"
      :height="imageHeight"
      :mobile-width="imageWidthMobile"
      :mobile-height="imageHeightMobile"
      mode="crop"
      class="background-teaser__image"
    />
    <StaticImage
      v-if="!article.attributes.teaser.image && size === 'big'"
      url="/placeholder_350x452.jpg"
      urlmobile="/placeholder_350x452.jpg"
      alt="Das Bild konnte nicht geladen werden"
      title="Das Bild konnte nicht geladen werden"
      class="background-teaser__image"
    />
    <StaticImage
      v-if="!article.attributes.teaser.image && size === 'small'"
      url="/placeholder_350x214.jpg"
      urlmobile="/placeholder_350x214.jpg"
      alt="Das Bild konnte nicht geladen werden"
      title="Das Bild konnte nicht geladen werden"
      class="background-teaser__image"
    />
    <SubHeadline tag="h3" class="background-teaser__headline">
      <ArticleLink v-snip="2" :article="article" :teaserlink="true" class="background-teaser__headline-link">
        {{ article.attributes.teaser.title }}
      </ArticleLink>
    </SubHeadline>
  </article>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import StaticImage from '@/components/ui/image/static'
import SubHeadline from '@/components/ui/text/subheadline'
import ArticleLink from '@/components/article/link'

export default {
  components: {
    SpunqImage,
    SubHeadline,
    ArticleLink,
    StaticImage,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    imageWidth: {
      type: Number,
      required: true,
    },
    imageHeight: {
      type: Number,
      required: true,
    },
    imageWidthMobile: {
      type: Number,
      required: true,
    },
    imageHeightMobile: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.background-teaser {
  position: relative;
  //min-height: 214px;
  background: $color-light-gray;

  &:before {
    content: ' ';
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__image {
    width: 100%;

    @media screen and (min-width: $screen-width-767) {
      max-width: 350px;
    }
  }

  &__headline {
    position: absolute;
    z-index: 1;
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    font-size: 22px;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;

    &-link {
      margin: 0 0 1rem 1rem;
      color: $color-white;

      &:active,
      &:focus {
        color: $color-white;
      }
    }
  }
}
</style>
