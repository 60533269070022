<template>
  <button class="slider-arrow-round">
    <IconSliderArrow width="8" height="17" />
  </button>
</template>

<script>
import IconSliderArrow from '@/components/ui/icons/icon-slider-arrow.svg'

export default {
  components: {
    IconSliderArrow,
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';
.slider-arrow-round {
  width: 40px;
  height: 40px;
  background: $color-light-gray;
  border-radius: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  align-items: center;
  justify-content: center;

  &:active,
  &:focus {
    background: $color-light-gray;
  }

  &.swiper-button-disabled {
    display: none;
  }
}
</style>
