<template>
  <GridRow v-if="collection">
    <div class="books-teaser-articles">
      <PreTitle>
        {{ box.attributes.templateOptions.preTitle }}
      </PreTitle>
      <SubHeadline tag="h2">
        {{ box.attributes.templateOptions.title }}
      </SubHeadline>
      <AvSwiper
        v-show="isSwiperVisible"
        :id="`books-swiper-${box.id}`"
        :options="swiperOptions"
        class="books-teaser-articles__content"
      >
        <AvSwiperItem v-for="article in collection.relationships.articles" :key="article.id">
          <BooksTeaser :article="article" />
        </AvSwiperItem>
      </AvSwiper>
      <a
        v-if="box.attributes.templateOptions.targetUrl"
        :href="box.attributes.templateOptions.targetUrl"
        class="books-teaser-articles__button"
      >
        zu Buchaktuell
      </a>
    </div>
  </GridRow>
</template>

<script>
import AvSwiper from '@/components/ui/swipers/av-swiper'
import AvSwiperItem from '@/components/ui/swipers/av-swiper-item'
import GridRow from '@/components/ui/grid/row'
import SubHeadline from '@/components/ui/text/subheadline'
import PreTitle from '@/components/ui/text/pretitle'
import BooksTeaser from '@/components/ui/teaser/books-teaser'

export default {
  components: {
    AvSwiper,
    AvSwiperItem,
    GridRow,
    SubHeadline,
    PreTitle,
    BooksTeaser,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSwiperVisible: false,
      swiperOptions: {
        pagination: {
          el: `#swiper-pagination-${this.box.id}`,
        },
        spaceBetween: 20,
        slidesPerView: 1.5,
        a11y: true,
        keyboardControl: false,
        centeredSlides: false,
        allowTouchMove: true,
        simulateTouch: true,
        preloadImages: false,
        lazy: false,
        loop: false,
        breakpoints: {
          767: {
            slidesPerView: 4,
            spaceBetween: 89,
            loop: true,
          },
        },
      },
    }
  },
  computed: {
    collection() {
      if (this.box.attributes && this.box.attributes.templateOptions) {
        return this.box.attributes.templateOptions.collection
      }
      return null
    },
  },
  mounted() {
    this.isSwiperVisible = true
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.books-teaser-articles {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $page-max-width;
  align-items: center;
  justify-content: center;

  &__content {
    width: 100%;
    position: relative;
    max-width: 1140px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;

    @media screen and (min-width: $screen-width-767) {
      padding: 0 10px;
    }

    @media screen and (min-width: $screen-width-1110) {
      padding: 0;
    }
  }

  &__button {
    width: 100%;
    text-align: center;
    border-radius: 4px;
    color: $color-white;
    background-color: $color-red;
    border: none;
    font-size: 18px;
    padding: 0.4rem 0.7rem;
    margin-top: 20px;
    max-width: calc(100% - 26px);

    @media all and (min-width: $screen-width-767) {
      max-width: 222px;
      margin-top: 40px;
    }

    &:hover {
      transition-duration: 0.3s;
      background-color: darken($color-red, 5%);
    }

    &:focus {
      color: $color-white;
      transition-duration: unset;
    }
  }
}
</style>
