<template>
  <div class="da-article-teaser">
    <SpunqImage v-if="articleImage" :image="articleImage" :height="200" :width="270" class="da-article-teaser__image" />
    <StaticImage
      v-else
      url="/placeholder_350x452.jpg"
      urlmobile="/placeholder_350x452.jpg"
      alt="Das Bild konnte nicht geladen werden"
      title="Das Bild konnte nicht geladen werden"
      class="da-article-teaser__image"
    />
    <h3 class="da-article-teaser__title">
      <ArticleLink v-snip="2" :article="article" :teaserlink="true">
        {{ articleTitle }}
      </ArticleLink>
    </h3>
    <p v-snip="5" class="da-article-teaser__leadtext">
      {{ articleLeadText }}
    </p>
  </div>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import StaticImage from '@/components/ui/image/static'
import ArticleLink from '@/components/article/link'

export default {
  components: {
    SpunqImage,
    StaticImage,
    ArticleLink,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    articleTitle() {
      return this.article.attributes.title
    },
    articleLeadText() {
      return this.article.attributes.teaser.leadText
    },
    articleImage() {
      return this.article.attributes.teaser.image
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.da-article-teaser {
  &__image {
    @media screen and (min-width: $screen-width-767) {
      img {
        max-height: 200px;
      }
    }
  }

  &__title {
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    line-height: 24px;
    font-size: 18px;
    margin: 18px 0 10px 0;

    a {
      color: $color-headline;
    }
  }

  &__leadtext {
    font-size: 16px;
    color: $color-text-gray;
  }
}
</style>
