<template>
  <GridRow v-if="paperItems.length > 0">
    <div class="papers-box">
      <PreTitle>
        {{ box.attributes.templateOptions.preTitle }}
      </PreTitle>
      <SubHeadline tag="h2">
        {{ box.attributes.templateOptions.title }}
      </SubHeadline>
      <div class="papers-box__content">
        <SliderButtonRound
          :id="`slider-button-prev-${box.id}`"
          class="papers-box__slidebutton papers-box__slidebutton--prev"
        />
        <SliderButtonRound
          :id="`slider-button-next-${box.id}`"
          class="papers-box__slidebutton papers-box__slidebutton--next"
        />
        <div v-swiper:papersSwiper="swiperOptions">
          <div class="swiper-wrapper">
            <div v-for="(paper, index) in paperItems" :key="index" class="swiper-slide">
              <PaperTeaser :paper="paper" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import SubHeadline from '@/components/ui/text/subheadline'
import PreTitle from '@/components/ui/text/pretitle'
import PaperTeaser from '@/components/ui/teaser/paper'
import SliderButtonRound from '@/components/ui/button/slider-button-round'

export default {
  components: {
    GridRow,
    SubHeadline,
    PreTitle,
    SliderButtonRound,
    PaperTeaser,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
    paperItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: `#slider-button-next-${this.box.id}`,
          prevEl: `#slider-button-prev-${this.box.id}`,
        },
        a11y: true,
        keyboardControl: false,
        centeredSlides: false,
        allowTouchMove: true,
        simulateTouch: true,
        preloadImages: false,
        lazy: true,
        spaceBetween: 20,
        slidesPerView: 1.5,
        loop: true,
        breakpoints: {
          767: {
            slidesPerView: 4,
            spaceBetween: 89,
            loop: false,
          },
        },
      },
    }
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.papers-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $page-max-width;
  align-items: center;
  justify-content: center;

  &__content {
    width: 100%;
    position: relative;
    max-width: 1140px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;

    @media screen and (min-width: $screen-width-767) {
      padding: 0 10px;
    }

    @media screen and (min-width: $screen-width-1110) {
      padding: 0;
    }
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-wrapper {
    width: 100%;

    @media all and (min-width: $screen-width-767) {
    }
  }

  &__slidebutton {
    position: absolute;
    right: 20px;
    display: none;

    @media screen and (min-width: $screen-width-767) {
      display: flex;
    }

    @media screen and (min-width: $screen-width-1220) {
      right: -45px;
    }

    svg {
      transform: translate3d(2px, 0, 0);
      margin-right: 3px;
    }

    &--prev {
      right: auto;
      left: 20px;

      @media screen and (min-width: $screen-width-1220) {
        left: -45px;
      }

      svg {
        margin-right: 3px;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
