<template>
  <GridRow v-if="collection">
    <div class="elearning-articles">
      <PreTitle>
        {{ box.attributes.templateOptions.preTitle }}
      </PreTitle>
      <SubHeadline tag="h2">
        {{ box.attributes.templateOptions.title }}
      </SubHeadline>
      <div v-show="isSwiperVisible" class="elearning-articles__content">
        <SliderButtonRound
          :id="`slider-button-prev-${box.id}`"
          class="elearning-articles__slidebutton elearning-articles__slidebutton--prev"
        />
        <SliderButtonRound
          :id="`slider-button-next-${box.id}`"
          class="elearning-articles__slidebutton elearning-articles__slidebutton--next"
        />
        <div v-swiper:elearningSwiper="swiperOptions">
          <div class="swiper-wrapper">
            <ElearningTeaser
              v-for="(article, index) in collection.relationships.articles"
              :key="index"
              :article="article"
              class="swiper-slide"
            />
          </div>
        </div>
      </div>
      <a
        v-if="box.attributes.templateOptions.targetUrl"
        :href="box.attributes.templateOptions.targetUrl"
        class="elearning-articles__button"
      >
        zum e-Learning Portal
      </a>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import SubHeadline from '@/components/ui/text/subheadline'
import PreTitle from '@/components/ui/text/pretitle'
import SliderButtonRound from '@/components/ui/button/slider-button-round'
import ElearningTeaser from '@/components/ui/teaser/elearning'

export default {
  components: {
    GridRow,
    SubHeadline,
    PreTitle,
    SliderButtonRound,
    ElearningTeaser,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: `#slider-button-next-${this.box.id}`,
          prevEl: `#slider-button-prev-${this.box.id}`,
        },
        pagination: {
          el: `#swiper-pagination-${this.box.id}`,
        },

        spaceBetween: 20,
        slidesPerView: 1.5,
        a11y: true,
        keyboardControl: false,
        centeredSlides: false,
        allowTouchMove: true,
        simulateTouch: true,
        preloadImages: false,
        lazy: true,
        loop: true,
        breakpoints: {
          767: {
            slidesPerView: 4,
            spaceBetween: 89,
            loop: false,
          },
        },
      },
      isSwiperVisible: false,
    }
  },
  computed: {
    collection() {
      if (this.box.attributes && this.box.attributes.templateOptions) {
        return this.box.attributes.templateOptions.collection
      }
      return null
    },
  },
  mounted() {
    this.isSwiperVisible = true
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.elearning-articles {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $page-max-width;
  align-items: center;
  justify-content: center;

  &__content {
    width: 100%;
    position: relative;
    max-width: 1140px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;

    @media screen and (min-width: $screen-width-767) {
      padding: 0 10px;
    }

    @media screen and (min-width: $screen-width-1110) {
      padding: 0;
    }
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-wrapper {
    width: 100%;

    @media all and (min-width: $screen-width-767) {
    }
  }

  &__slidebutton {
    position: absolute;
    right: 20px;
    display: none;

    @media screen and (min-width: $screen-width-767) {
      display: flex;
    }

    @media screen and (min-width: $screen-width-1220) {
      right: -45px;
    }

    svg {
      transform: translate3d(2px, 0, 0);
      margin-right: 3px;
    }

    &--prev {
      right: auto;
      left: 20px;

      @media screen and (min-width: $screen-width-1220) {
        left: -45px;
      }

      svg {
        margin-right: 3px;
        transform: rotate(180deg);
      }
    }
  }

  &__button {
    width: 100%;
    text-align: center;
    border-radius: 4px;
    color: $color-white;
    background-color: $color-red;
    border: none;
    font-size: 18px;
    padding: 0.4rem 0.7rem;
    margin-top: 20px;
    max-width: calc(100% - 26px);

    @media all and (min-width: $screen-width-767) {
      max-width: 222px;
      margin-top: 40px;
    }

    &:hover {
      transition-duration: 0.3s;
      background-color: darken($color-red, 5%);
    }

    &:focus {
      color: $color-white;
      transition-duration: unset;
    }
  }
}
</style>
