<template>
  <GridRow v-if="box.attributes.templateOptions.navigation">
    <div class="product-navigation-box">
      <SubHeadline tag="h2">
        {{ box.attributes.templateOptions.title }}
      </SubHeadline>
      <nav class="navigation-lists">
        <ul v-for="productCategory in productCategories" :key="productCategory.id" class="navigation-list" role="menu">
          <NavigationItem
            css-class-li="navigation-list__topitem"
            css-class="navigation-list__link"
            :navitem="productCategory"
          />
          <li class="navigation-list__topitem">
            <ul v-if="productCategory.attributes.children">
              <NavigationItem
                v-for="product in productCategory.attributes.children"
                :key="product.id"
                css-class-li="navigation-list__item"
                css-class="navigation-list__link"
                :navitem="product"
              />
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import SubHeadline from '@/components/ui/text/subheadline'
import NavigationItem from '@/components/ui/navigation/navigation-item'

export default {
  components: {
    GridRow,
    SubHeadline,
    NavigationItem,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productCategories() {
      return this.box.attributes.templateOptions.navigation.attributes.children
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.product-navigation-box {
  width: 100%;
  max-width: $page-max-width;
  padding-top: 40px;

  @media all and (min-width: $screen-width-767) {
    padding-top: 80px;
  }

  h2.subheadline {
    font-size: 36px !important;

    @media all and (min-width: $screen-width-767) {
      font-size: 42px !important;
    }
  }

  .navigation-lists {
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    max-width: $content-max-width;
  }

  .navigation-list {
    flex-basis: calc(25% - 50px);
    flex-grow: 1;
    margin: 0;
    padding: 25px;

    &__item {
      position: relative;
      padding-left: 20px;
      font-size: 18px;
      line-height: 1.3333;
      margin-bottom: 12px;
      white-space: nowrap;

      @media screen and (min-width: $screen-width-767) {
        margin-bottom: 5px;
        white-space: initial;
      }

      &:before {
        content: ' ';
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        top: 8px;
        display: block;
        background-color: $color-red;
        border-radius: 100%;
      }
    }

    &__topitem {
      font-weight: 600;
      font-variation-settings: 'wght' 600;
      font-size: 22px;
      margin-bottom: 20px;
      padding-left: 0;

      &:before {
        display: none;
      }
    }

    &__link {
      color: $color-black;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
