<template>
  <div class="grid-row">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.grid {
  &-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 40px;
    position: relative;

    @media all and (min-width: $screen-width-767) {
      flex-direction: row;
      margin-bottom: 80px;
    }

    &--wrap {
      @media all and (min-width: $screen-width-767) {
        flex-wrap: wrap;
      }
    }
  }
}
</style>
