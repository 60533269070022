<script>
export default {
  props: {
    tag: {
      default: 'span',
      required: false,
      type: String,
    },
  },
  render(createElement) {
    return createElement(
      this.tag,
      {
        class: 'pretitle',
      },
      this.$slots.default
    )
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.pretitle {
  margin-bottom: 5px;
  font-weight: 400;
  font-variation-settings: 'wght' 400;
  color: $color-red;
  font-size: 16px;
  letter-spacing: 2.67px;
  text-transform: uppercase;

  @media all and (min-width: $screen-width-767) {
    font-size: 18px;
  }
}
</style>
