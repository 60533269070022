import CHANNEL from '@/gql/channels.gql'
import SHOPFEED from '@/gql/shopfeed.gql'
import PAPERFEED from '@/gql/paperfeed.gql'

const asyncData = async function ({ app, route, redirect, error, $config: { portalId } }) {
  const client = app.apolloProvider.defaultClient
  const slug = route.params.slug
  const values = {
    channels: [],
    shopItems: [],
    paperItems: [],
  }
  try {
    /**
     * NOTE: params.channelSlugs is a string when resolving, so we need to create the
     * array manually.
     */
    const channelSlugs =
      typeof route.params.channelSlugs === 'string'
        ? route.params.channelSlugs.split('/')
        : [].concat(route.params.channelSlugs)
    const queryParameter = [...channelSlugs, slug].filter((c) => !!c).join('/')
    const { data } = await client.query({
      query: CHANNEL,
      variables: {
        portalId: app.$config.portalId,
        filter_slug: queryParameter,
      },
    })
    if (data.getChannelsByPortalId === null) {
      // start page condition, should exist but does not
      if (channelSlugs[0] === undefined) {
        // eslint-disable-next-line no-console
        console.error(`Portal(${app.$config.portalId}) does not contain / channel!`)
        return error({ statusCode: 500 })
      }
      return error({ statusCode: 404 })
    }
    values.channels = data.getChannelsByPortalId.data
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return error({ statusCode: 500 })
  }
  const boxes = values.channels[0].relationships.boxes
  try {
    if (
      boxes.filter((box) => box.attributes.templateAlias.replace(/cms\.apoverlag\._boxes\.Box\./, '') === 'item-teaser')
        .length > 0
    ) {
      const { data } = await client.query({
        query: SHOPFEED,
      })
      values.shopItems = data.getFeedProducts.data
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  try {
    if (
      boxes.filter((box) => box.attributes.templateAlias.replace(/cms\.apoverlag\._boxes\.Box\./, '') === 'papers')
        .length > 0
    ) {
      const { data } = await client.query({
        query: PAPERFEED,
      })
      values.paperItems = data.getPublikationList.data
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  return values
}
export default asyncData
