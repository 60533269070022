<template>
  <GridRow>
    <div class="product-teaser-box">
      <div class="product-teaser-box__container">
        <PreTitle>
          {{ box.attributes.templateOptions.preTitle }}
        </PreTitle>
        <SubHeadline v-if="box.attributes.templateOptions.title" tag="h2">
          {{ box.attributes.templateOptions.title }}
        </SubHeadline>
        <div v-if="box.relationships.articles.length > 0" class="product-teaser-box__teasers">
          <ProductTeaser
            v-for="(article, index) in box.relationships.articles"
            :key="index"
            class="product-teaser-box__teaser"
            :article="article"
          />
        </div>
      </div>
      <a
        v-if="box.attributes.templateOptions.targetUrl"
        :href="box.attributes.templateOptions.targetUrl"
        class="product-teaser-box__button"
      >
        zur Produktübersicht
      </a>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import ProductTeaser from '@/components/ui/teaser/product'
import SubHeadline from '@/components/ui/text/subheadline'
import PreTitle from '@/components/ui/text/pretitle'

export default {
  components: {
    GridRow,
    ProductTeaser,
    SubHeadline,
    PreTitle,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.product-teaser-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $page-max-width;
    align-items: center;
    justify-content: center;

    @media all and (min-width: $screen-width-1440) {
      padding-bottom: 60px;
    }
  }

  &__teasers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: $content-max-width;
    flex-wrap: wrap;

    @media all and (min-width: $screen-width-767) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__teaser {
    margin: 5px;
    width: calc(50% - 20px);
    max-width: calc(50% - 20px);
    height: auto;

    @media screen and (min-width: $screen-width-767) {
      width: calc(25% - 20px);
      max-width: calc(25% - 20px);
      margin: 0 10px;
      height: 360px;
    }

    @media screen and (min-width: $screen-width-1110) {
      margin: 0;
    }
  }

  &__button {
    width: 100%;
    text-align: center;
    border-radius: 4px;
    color: $color-white;
    background-color: $color-red;
    border: none;
    font-size: 18px;
    padding: 0.4rem 0.7rem;
    margin-top: 20px;
    max-width: calc(100% - 26px);

    @media all and (min-width: $screen-width-767) {
      max-width: 222px;
      margin-top: 40px;
    }

    &:hover {
      transition-duration: 0.3s;
      background-color: darken($color-red, 5%);
    }

    &:focus {
      color: $color-white;
      transition-duration: unset;
    }
  }
}
</style>
