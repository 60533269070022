<template>
  <article class="product-teaser">
    <h3 title="">
      <ArticleLink
        v-if="!article.attributes.teaser.link"
        :article="article"
        :teaserlink="true"
        class="product-teaser__link"
      >
        <PageLogoFortbildung v-if="category === 'fortbildung'" />
        <PageLogoMedien v-if="category === 'medien'" />
        <PageLogoPharmainfo v-if="category === 'pharmainfo'" />
        <PageLogoSoftware v-if="category === 'software'" />
      </ArticleLink>
      <ArticleExternalLink v-else :article="article" :teaserlink="true" class="product-teaser__link">
        <PageLogoFortbildung v-if="category === 'fortbildung'" />
        <PageLogoMedien v-if="category === 'medien'" />
        <PageLogoPharmainfo v-if="category === 'pharmainfo'" />
        <PageLogoSoftware v-if="category === 'software'" />
      </ArticleExternalLink>
    </h3>
    <p v-snip="6" class="product-teaser__text">
      {{ article.attributes.teaser.leadText }}
    </p>
  </article>
</template>

<script>
import ArticleLink from '@/components/article/link'
import ArticleExternalLink from '@/components/article/external-link'

import PageLogoFortbildung from '@/components/ui/icons/apothekerverlag-logo-fortbildung.svg'
import PageLogoMedien from '@/components/ui/icons/apothekerverlag-logo-medien.svg'
import PageLogoPharmainfo from '@/components/ui/icons/apothekerverlag-logo-pharmainfo.svg'
import PageLogoSoftware from '@/components/ui/icons/apothekerverlag-logo-software.svg'

export default {
  components: {
    ArticleLink,
    PageLogoFortbildung,
    PageLogoMedien,
    PageLogoPharmainfo,
    PageLogoSoftware,
    ArticleExternalLink,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    category() {
      if (this.article.attributes.options && this.article.attributes.options.category !== null) {
        return this.article.attributes.options.category.toLowerCase()
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.product-teaser {
  position: relative;
  background: $color-white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 50px 15px 50px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media all and (min-width: $screen-width-767) {
    padding: 55px 15px 15px 15px;
  }

  h3 {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      width: 90%;
    }
  }

  &-link {
    color: $color-text;

    &:active,
    &:focus {
      color: $color-text;
    }
  }

  &--software {
    background-color: $color-software;
  }

  &--medien {
    background-color: $color-medien;
  }

  &--fortbildung {
    background-color: $color-fortbildung;
  }

  &--pharmainfo {
    background-color: $color-pharmainfo;
  }

  &__text {
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: $color-text-light;
    display: none;

    @media all and (min-width: $screen-width-767) {
      font-size: 14px;
      display: block;
    }
    @media all and (min-width: $screen-width-1023) {
      font-size: 18px;
    }
  }
}
</style>
