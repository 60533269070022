<script>
export default {
  props: {
    tag: {
      default: 'h2',
      required: false,
      type: String,
    },
  },
  render(createElement) {
    return createElement(
      this.tag,
      {
        class: 'subheadline',
      },
      this.$slots.default
    )
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.subheadline {
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: 200;
  font-variation-settings: 'wght' 200;
}
</style>
