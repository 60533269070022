<template>
  <GridRow v-if="box.attributes.templateOptions.navigation">
    <div class="product-navigation-filter-box">
      <SubHeadline tag="h2">
        {{ box.attributes.templateOptions.title }}
      </SubHeadline>
      <div class="category-filters">
        <StyledSelect
          id="product-navigation-filter-box-select"
          class="category-filters__mobile"
          :options="options"
          @change="(value) => (activeFilter = value)"
        />
        <div class="category-filters__desktop">
          <nav>
            <ul>
              <li :class="{ active: isActiveFilter('pharmacy') }" @click="toggleFilter('pharmacy')">Apotheke</li>
              <!-- item-pharmacy -->
              <li :class="{ active: isActiveFilter('doctor') }" @click="toggleFilter('doctor')">Arztpraxis</li>
              <!-- item-doctor -->
              <li :class="{ active: isActiveFilter('hospital') }" @click="toggleFilter('hospital')">
                Gesundheitseinrichtung
              </li>
              <!-- item-hospital -->
              <li :class="{ active: isActiveFilter('pharma') }" @click="toggleFilter('pharma')">Industrie</li>
              <!-- item-pharma -->
              <li :class="{ active: isActiveFilter('all') }" @click="toggleFilter('all')">Alle</li>
              <!-- item-all -->
            </ul>
          </nav>
        </div>
        <div class="categories-lists" :class="activeFilter">
          <template v-for="(child, index) in box.attributes.templateOptions.navigation.attributes.children">
            <div
              v-if="getListItems(child).length"
              :key="index"
              :aria-expanded="isActiveNav(child.attributes.link.attributes.text) ? 'true' : 'false'"
              class="product-category-list"
              @click="toggleNav(child.attributes.link.attributes.text)"
            >
              <h3 class="product-category-list__headline">
                {{ child.attributes.link.attributes.text }}
              </h3>
              <ul v-if="child.attributes.children" class="product-category-list__content">
                <NavigationItem
                  v-for="(subchild, subchildIndex) in getListItems(child)"
                  :key="subchildIndex"
                  :css-class-li="`navigation-list__item ${subchild.attributes.link.attributes.cssClasses.join(' ')}`"
                  css-class="navigation-list__link"
                  :navitem="subchild"
                />
              </ul>
            </div>
          </template>
        </div>
      </div>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import SubHeadline from '@/components/ui/text/subheadline'
import StyledSelect from '@/components/ui/form/select/default'
import NavigationItem from '@/components/ui/navigation/navigation-item'

export default {
  components: {
    GridRow,
    SubHeadline,
    StyledSelect,
    NavigationItem,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeFilter: 'pharmacy',
      activeNav: null,
      options: [
        {
          name: 'Öffentliche Apotheken und Hausapotheken',
          value: 'pharmacy',
        },
        {
          name: 'Niedergelassene Arztpraxen',
          value: 'doctor',
        },
        {
          name: 'Krankenanstalten, Rehabilitations- und Pflegeeinrichtungen',
          value: 'hospital',
        },
        {
          name: 'Industrie, Versicherungen und Behörden',
          value: 'pharma',
        },
        {
          name: 'Alle Produkte',
          value: 'all',
        },
      ],
    }
  },
  methods: {
    isActiveNav(id) {
      return id === this.activeNav
    },
    toggleNav(id) {
      if (this.activeNav === id) {
        this.activeNav = null
      } else {
        this.activeNav = id
      }
    },
    isActiveFilter(id) {
      return id === this.activeFilter
    },
    toggleFilter(id) {
      this.activeFilter = id
    },
    getListItems(list) {
      return list.attributes.children.filter((child) =>
        child.attributes.link.attributes.cssClasses.includes(`item-${this.activeFilter}`)
      )
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.product-navigation-filter-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $page-max-width;
  align-items: center;
  justify-content: center;
  padding-top: 40px;

  @media all and (min-width: $screen-width-767) {
    padding-top: 80px;
  }

  h2.subheadline {
    font-size: 36px !important;

    @media all and (min-width: $screen-width-767) {
      font-size: 42px !important;
    }
  }

  .category-filters {
    width: 100%;
    max-width: $content-max-width;

    @media screen and (min-width: $screen-width-767) {
      padding: 10px;
    }

    &__mobile {
      display: block;
      margin: 0 10px;
      width: calc(100% - 20px);

      @media screen and (min-width: $screen-width-767) {
        display: none;
      }
    }

    &__desktop {
      display: none;

      @media screen and (min-width: $screen-width-767) {
        display: flex;
      }

      nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        width: 100%;
        border-bottom: 1px solid $color-light-gray;
        padding-bottom: 20px;

        li {
          margin: 0 2px;
          border-radius: 4px;
          background-color: $color-white;
          width: auto;
          border: none;
          font-size: 18px;
          padding: 0.4rem 0.7rem;
          cursor: pointer;

          &:hover,
          &.active {
            color: $color-white;
            background-color: $color-red;
          }

          &:before {
            display: none;
          }
        }
      }
    }
  }

  .categories-lists {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 40px;

    @media screen and (min-width: $screen-width-767) {
      flex-direction: row;
    }

    .product-category-list {
      width: 100%;
      border-top: 1px solid $color-light-gray;
      margin-right: 18px;

      &:last-child {
        border-bottom: 1px solid $color-light-gray;
        margin-right: 0;

        @media screen and (min-width: $screen-width-767) {
          border: none;
        }
      }

      @media screen and (min-width: $screen-width-767) {
        border: none;
      }

      &__headline {
        font-weight: 600;
        font-variation-settings: 'wght' 600;
        font-size: 24px;
        margin: 10px 0;
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        @media screen and (min-width: $screen-width-767) {
          padding: 0;
          margin: 10px 0 20px 0;
          cursor: default;
        }

        &:after {
          content: ' ';
          background-image: url('~assets/svg/icon-plus-2.svg');
          background-repeat: no-repeat;
          width: 18px;
          height: 18px;

          @media screen and (min-width: $screen-width-767) {
            display: none;
          }
        }
      }

      &__content {
        max-height: 0;
        overflow: hidden;
        margin: 0;
        padding: 0 10px;

        @media all and (min-width: $screen-width-767) {
          max-height: 100%;
          padding: 0;
        }
      }

      &[aria-expanded='true'] {
        .product-category-list__headline:after {
          background-image: url('~assets/svg/icon-minus.svg');
          height: 3px;
        }
        .product-category-list__content {
          transition-duration: 0.5s;
          max-height: 500px;
          padding-bottom: 20px;
          padding-top: 20px;
          border-top: 1px solid $color-light-gray;

          @media all and (min-width: $screen-width-767) {
            border: none;
          }
        }
      }
    }

    .navigation-list {
      &__item {
        padding-bottom: 10px;
        font-size: 17px;
        word-break: break-word;

        &::before {
          background-color: $color-pharmainfo;
        }
      }

      &__link {
        color: $color-pharmainfo;
      }
    }
  }
}
</style>
