<template>
  <a
    :href="article.attributes.teaser.link"
    :target="article.attributes.teaser.linkTarget"
    :class="{ 'teaser-link': teaserlink }"
  >
    <slot>
      {{ article.attributes.title }}
    </slot>
  </a>
</template>

<script>
export default {
  components: {},
  props: {
    article: {
      type: Object,
      required: true,
    },
    teaserlink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.teaser-link {
  &:after {
    content: '/a';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
  }
}
</style>
