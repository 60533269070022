<template>
  <article class="books-teaser">
    <SpunqImage
      v-if="article.attributes.teaser.image"
      :image="article.attributes.teaser.image"
      :width="350"
      :height="470"
      :mobile-width="350"
      :mobile-height="470"
      mode="crop"
      class="books-teaser__image"
    />
    <SubHeadline tag="h3" class="books-teaser__headline">
      <ArticleLink
        v-if="!article.attributes.teaser.link"
        :article="article"
        :teaserlink="true"
        class="books-teaser__link"
      >
        {{ article.attributes.teaser.title }}
      </ArticleLink>
      <ArticleExternalLink v-else :article="article" :teaserlink="true" class="books-teaser__link">
        {{ article.attributes.teaser.title }}
      </ArticleExternalLink>
    </SubHeadline>
    <p
      v-if="article.attributes.teaser.leadText"
      v-snip="3"
      :title="article.attributes.teaser.leadText"
      class="books-teaser__text"
    >
      {{ article.attributes.teaser.leadText }}
    </p>
  </article>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import SubHeadline from '@/components/ui/text/subheadline'
import ArticleLink from '@/components/article/link'
import ArticleExternalLink from '@/components/article/external-link'

export default {
  components: {
    SpunqImage,
    SubHeadline,
    ArticleLink,
    ArticleExternalLink,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.books-teaser {
  width: 100%;
  padding: 15px;

  &__image {
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 7px 13px rgba(0, 0, 0, 0.2);
  }

  &__headline {
    font-size: 18px;
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    line-height: 120%;
    margin: 0 0 10px 0;

    @media all and (min-width: $screen-width-767) {
      font-size: 18px;
    }
  }

  &__link {
    color: $color-headline;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &:hover {
    .books-teaser__link {
      text-decoration: underline;
    }
  }

  &__text {
    font-size: 16px;
    color: $color-text-gray;
  }
}
</style>
