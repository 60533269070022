<template>
  <figure class="spunq-image">
    <picture v-if="lazy">
      <source ref="sourceMobile" :data-srcset="urlmobile" :srcset="mobilePlaceholder" media="(max-width: 767px)" />
      <source ref="source" :data-srcset="url" :srcset="placeholder" media="(min-width: 768px)" />
      <img
        ref="image"
        :alt="alt"
        :title="title"
        :data-src="url"
        :src="placeholder"
        :class="loading ? 'lazyload' : 'lazyloaded'"
      />
    </picture>
    <picture v-else>
      <source :srcset="urlmobile" media="(max-width: 767px)" />
      <source :srcset="url" media="(min-width: 768px)" />
      <img :alt="alt" :title="title" :src="url" />
    </picture>
    <slot />
  </figure>
</template>

<script>
/**
 * From: https://markus.oberlehner.net/blog/lazy-loading-responsive-images-with-vue/
 */

import lozad from 'lozad'

export default {
  name: 'SpunqImage',
  props: {
    lazy: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
    urlPlaceholder: {
      type: String,
      required: false,
      default: '/placeholder-image_310x207.jpg',
    },
    urlmobile: {
      type: String,
      required: true,
    },
    urlmobilePlaceholder: {
      type: String,
      required: false,
      default: '/placeholder-image_345x230.jpg',
    },
    alt: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    aspectRatio: {
      type: Number,
      default: null,
    },
    aspectRatioMobile: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    placeholder() {
      return this.urlPlaceholder || this.url
    },
    mobilePlaceholder() {
      return this.urlmobilePlaceholder || this.urlmobile
    },
  },
  mounted() {
    if (!this.lazy) {
      this.loading = false
      return
    }
    // As soon as the <img> element triggers
    // the `load` event, the loading state is
    // set to `false`, which removes the apsect
    // ratio we've applied earlier.
    // Change: Since we use a placeholder we need to check
    // for the lodaz callback
    const setLoadingState = () => {
      this.loading = !this.$refs.image.getAttribute('data-loaded')
    }

    const elements = [this.$refs.image, this.$refs.source, this.$refs.sourceMobile]
    elements[0].addEventListener('load', setLoadingState)
    // We remove the event listener as soon as
    // the component is destroyed to prevent
    // potential memory leaks.
    this.$once('hook:destroyed', () => {
      elements[0].removeEventListener('load', setLoadingState)
    })

    // We initialize Lozad.js on each element individualy
    // since the picture functionality doesn't work with vue
    elements.forEach((el) => {
      const observer = lozad(el)
      observer.observe()
    })
  },
}
</script>

<style lang="scss">
.spunq-image {
  img {
    width: 100%;
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    transition: filter 0.7s, transform 0.7s;
  }
}

.lazyloading,
.lazyload {
  filter: blur(10px);
}

.lazyloaded {
  // filter: blur(0px);
}
</style>
