<template>
  <article class="paper-teaser">
    <img v-if="paper.ausgaben[0].image_file" class="paper-teaser__image" :src="paper.ausgaben[0].image_file" />
    <SubHeadline v-if="paper.zs_bezeichnung" tag="h3" class="paper-teaser__headline">
      <a v-if="paper.ausgaben[0].epaper_view_url" class="paper-teaser__link" :href="paper.ausgaben[0].epaper_view_url">
        {{ paper.zs_bezeichnung }}
      </a>
      <template v-else>
        {{ paper.zs_bezeichnung }}
      </template>
    </SubHeadline>
    <p v-if="paper.ausgaben[0].teaser_text" class="paper-teaser__text">
      {{ paper.ausgaben[0].teaser_text }}
    </p>
  </article>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'

export default {
  components: {
    SubHeadline,
  },
  props: {
    paper: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.paper-teaser {
  width: 100%;
  padding: 15px;

  &__image {
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 7px 13px rgba(0, 0, 0, 0.2);

    @media all and (min-width: $screen-width-767) {
      max-width: 200px;
    }
  }

  &__headline {
    font-size: 18px;
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    line-height: 120%;
    margin: 0;

    @media all and (min-width: $screen-width-767) {
      font-size: 18px;
    }
  }

  &__link {
    color: $color-headline;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &:hover {
    .paper-teaser__link {
      text-decoration: underline;
    }
  }

  &__text {
    font-size: 16px;
    color: $color-text-gray;
  }
}
</style>
