<template>
  <article class="hero-teaser" :class="{ 'hero-teaser--with-title': hasTitle }">
    <SpunqImage
      v-if="article.attributes.teaser.image"
      :image="article.attributes.teaser.image"
      :width="1110"
      :height="480"
      :mobile-width="375"
      :mobile-height="mobileHeight"
      mode="crop"
      class="hero-teaser__image"
    />
    <header class="hero-teaser__header">
      <SubHeadline :tag="`h${headlineLevel}`" class="hero-teaser__headline">
        <ArticleLink
          v-if="!article.attributes.teaser.link"
          :article="article"
          :teaserlink="true"
          class="hero-teaser__headline-link"
        >
          {{ article.attributes.teaser.title }}
        </ArticleLink>
        <ArticleExternalLink v-else :article="article" :teaserlink="true" class="hero-teaser__headline-link">
          {{ article.attributes.teaser.title }}
        </ArticleExternalLink>
      </SubHeadline>
    </header>
  </article>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import SubHeadline from '@/components/ui/text/subheadline'
import ArticleLink from '@/components/article/link'
import ArticleExternalLink from '@/components/article/external-link'

export default {
  components: {
    SpunqImage,
    SubHeadline,
    ArticleLink,
    ArticleExternalLink,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    headlineLevel: {
      type: Number,
      required: false,
      default: 2,
    },
    mobileHeight: {
      type: Number,
      default: 440,
    },
  },
  computed: {
    hasTitle() {
      return this.article.attributes.title !== ' '
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.hero-teaser {
  position: relative;

  &__image {
    width: 100%;
  }

  &__header {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & &__headline {
    padding: 0 1rem;
    margin: 0;
    color: $color-white;
    z-index: 1;
    font-weight: 200;
    font-variation-settings: 'wght' 200;
    font-size: 42px;
    line-height: 48px;
    text-align: center;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    @media screen and (min-width: $screen-width-767) {
      padding: 0 6rem;
    }

    @media screen and (min-width: $screen-width-1023) {
      line-height: 120%;
      font-size: 58px;
    }

    &-link {
      color: $color-white;

      &:active,
      &:focus {
        color: $color-white;
      }
    }
  }

  &--with-title {
    &:before {
      content: ' ';
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
</style>
