<template>
  <AvSwiper v-show="isSwiperVisible" :id="`da-swiper-${id}`" :options="swiperOptions" class="da-collection-swiper">
    <AvSwiperItem v-for="article in articles" :key="article.id">
      <DaArticleTeaser :article="article" />
    </AvSwiperItem>
  </AvSwiper>
</template>

<script>
import AvSwiper from '@/components/ui/swipers/av-swiper'
import AvSwiperItem from '@/components/ui/swipers/av-swiper-item'
import DaArticleTeaser from '@/components/ui/teaser/da-article-teaser'

export default {
  components: {
    AvSwiper,
    AvSwiperItem,
    DaArticleTeaser,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    articles: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isSwiperVisible: false,
  }),
  computed: {
    swiperOptions() {
      return {
        slidesPerView: 1.5,
        loop: true,
        spaceBetween: 24,
        breakpoints: {
          767: {
            slidesPerView: 4,
          },
        },
      }
    },
  },
  mounted() {
    this.isSwiperVisible = true
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.da-collection-swiper {
  padding: 0 10px;

  @media screen and (min-width: $screen-width-1110) {
    padding: 0 15px;
  }
}
</style>
