<template>
  <GridRow v-if="collection">
    <div class="da-slider-box">
      <PreTitle class="da-slider-box__pretitle">
        {{ box.attributes.templateOptions.preTitle }}
      </PreTitle>
      <SubHeadline>
        {{ box.attributes.templateOptions.title }}
      </SubHeadline>
      <DaCollectionSwiper :id="box.id" :articles="collectionArticles" />
      <a
        v-if="box.attributes.templateOptions.targetUrl"
        :href="box.attributes.templateOptions.targetUrl"
        class="da-slider-box__button"
      >
        zu DeineApotheke.at
      </a>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import PreTitle from '@/components/ui/text/pretitle'
import SubHeadline from '@/components/ui/text/subheadline'
import DaCollectionSwiper from '@/components/ui/swipers/da-collection-swiper'

export default {
  components: {
    GridRow,
    PreTitle,
    SubHeadline,
    DaCollectionSwiper,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  computed: {
    collection() {
      return this.box.attributes.templateOptions.collection
    },

    collectionArticles() {
      return this.collection.relationships.articles
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.da-slider-box {
  width: 100%;
  max-width: 1140px;
  margin: auto;
  display: flex;
  flex-direction: column;

  &__pretitle {
    text-align: center;
  }

  &__button {
    width: 100%;
    text-align: center;
    border-radius: 4px;
    color: $color-white;
    background-color: $color-red;
    border: none;
    font-size: 18px;
    padding: 0.4rem 0.7rem;
    margin-top: 20px;
    align-self: center;
    max-width: calc(100% - 26px);

    @media all and (min-width: $screen-width-767) {
      max-width: 222px;
      margin-top: 40px;
    }

    &:hover {
      transition-duration: 0.3s;
      background-color: darken($color-red, 5%);
    }

    &:focus {
      color: $color-white;
      transition-duration: unset;
    }
  }
}
</style>
