<template>
  <div class="select">
    <select :id="id" @change="$emit('change', $event.target.value)" @blur="$emit('blur')">
      <option disabled selected>Bitte wählen</option>
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: null,
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.select {
  width: 100%;

  select {
    position: relative;
    width: 100%;
    background-color: $color-white;
    line-height: 100%;
    height: auto;
    font-family: $ff-pt-sans;
  }

  select {
    border-radius: 3px;
    background-image: url('~assets/svg/icon-arrow-down.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 10px 6px;
    font-size: 1.111rem;
    min-width: 170px;
    width: 100%;
    background-color: $color-white;
    padding: 1rem 40px 1rem 1rem;
    font-family: $ff-pt-sans;

    @media all and (min-width: $screen-width-767) {
      padding: 0.7rem 40px 0.7rem 0.7rem;
    }
  }

  select:-moz-focusring,
  &__tag:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  select::-ms-expand,
  &__tag::-ms-expand {
    display: none;
  }
}
</style>
