<template>
  <GridRow>
    <div class="news-teaser">
      <div class="news-teaser__content">
        <PreTitle class="news-teaser__pretitle">
          {{ box.attributes.templateOptions.preTitle }}
        </PreTitle>
        <SubHeadline tag="h2">
          {{ box.attributes.templateOptions.title }}
        </SubHeadline>
        <div class="news-teaser__items">
          <div class="news-teaser__item">
            <BackgroundTeaser
              :article="box.relationships.articles[0]"
              :image-width="350"
              :image-height="452"
              :image-width-mobile="335"
              :image-height-mobile="335"
              size="big"
            />
          </div>
          <div class="news-teaser__item news-teaser__item--nested">
            <BackgroundTeaser
              class="news-teaser__background-teaser"
              :article="box.relationships.articles[1]"
              :image-width="350"
              :image-height="214"
              :image-width-mobile="335"
              :image-height-mobile="200"
              size="small"
            />
            <BackgroundTeaser
              :article="box.relationships.articles[2]"
              :image-width="350"
              :image-height="214"
              :image-width-mobile="335"
              :image-height-mobile="200"
              size="small"
            />
          </div>
          <div class="news-teaser__item">
            <BackgroundTeaser
              :article="box.relationships.articles[3]"
              :image-width="350"
              :image-height="452"
              :image-width-mobile="335"
              :image-height-mobile="335"
              size="big"
            />
          </div>
        </div>
      </div>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import BackgroundTeaser from '@/components/ui/teaser/background'
import PreTitle from '@/components/ui/text/pretitle'
import SubHeadline from '@/components/ui/text/subheadline'

export default {
  components: {
    GridRow,
    BackgroundTeaser,
    PreTitle,
    SubHeadline,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.news-teaser {
  width: 100%;
  max-width: $page-max-width;
  display: flex;
  align-items: center;
  justify-content: center;

  &__pretitle {
    display: block;
    text-align: center;
  }

  &__content {
    width: 100%;
    max-width: $content-max-width;
    display: flex;
    flex-direction: column;
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: $screen-width-767) {
      flex-direction: row;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10px 10px 10px;

    @media screen and (min-width: $screen-width-1110) {
      margin: 0;
    }

    @media screen and (min-width: $screen-width-767) {
      max-width: 350px;
    }

    &--nested {
      .background-teaser {
        max-height: 214px;
        overflow: hidden;
      }
    }
  }

  &__background-teaser {
    margin-bottom: 10px;

    &:last-child {
      @media screen and (min-width: $screen-width-767) {
        margin-bottom: 0;
      }
    }
  }
}
</style>
