<template>
  <GridRow>
    <div class="quote-box">
      <blockquote class="quote-box__content">
        <p>
          <span class="quote-box__quote-start">”</span>
          {{ box.attributes.templateOptions.quote }}
          <span class="quote-box__quote-end">“</span>
          <span class="quote-box__author">{{ box.attributes.templateOptions.author }}</span>
        </p>
      </blockquote>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'

export default {
  components: {
    GridRow,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

blockquote.quote-box__content {
  &:before,
  &:after {
    display: none;
  }
}

.quote-box {
  width: 100%;
  max-width: $page-max-width;
  margin: auto;

  &__content {
    width: 100%;
    max-width: 1040px;
    padding: 1rem;
    margin: auto;
    text-align: center;

    p {
      font-size: 24px;
      font-weight: 200;
      font-variation-settings: 'wght' 200;
      padding: 0 15px;

      @media screen and (min-width: $screen-width-767) {
        font-size: 32px;
        padding: 0 30px;
      }
    }
  }

  &__quote-end,
  &__quote-start {
    color: $color-red;
    font-size: 28px;
    font-weight: 800;
    margin-right: 8px;
    font-variation-settings: 'wght' 800;
    line-height: 100%;

    @media screen and (min-width: $screen-width-767) {
      font-size: 42px;
    }
  }

  &__author {
    font-size: 16px;
    color: $color-info-gray;
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    display: block;
    padding: 15px 0 0 0;
  }
}
</style>
