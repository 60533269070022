<template>
  <div>
    <template v-for="box in channels[0].relationships.boxes">
      <div
        :is="sanitizeBoxTemplate(box.attributes.templateAlias)"
        v-if="sanitizeBoxTemplate(box.attributes.templateAlias) === 'item-teaser'"
        :key="parseInt(box.id)"
        :box="box"
        :shop-items="shopItems"
      />
      <div
        :is="sanitizeBoxTemplate(box.attributes.templateAlias)"
        v-else-if="sanitizeBoxTemplate(box.attributes.templateAlias) === 'papers-box'"
        :key="parseInt(box.id)"
        :box="box"
        :paper-items="paperItems"
      />
      <div :is="sanitizeBoxTemplate(box.attributes.templateAlias)" v-else :key="parseInt(box.id)" :box="box" />
    </template>
  </div>
</template>

<script>
import HeroSlider from '@/components/channel/boxes/box/hero-slider'
import DeineApothekeSlider from '@/components/channel/boxes/box/deine-apotheke-slider'
import QuoteBox from '@/components/channel/boxes/box/quote'
import NewsTeaser from '@/components/channel/boxes/content-box/news-teaser'
import ProductTeaser from '@/components/channel/boxes/content-box/product-teaser'
import PapersBox from '@/components/channel/boxes/box/papers'
import ItemTeaser from '@/components/channel/boxes/box/item-teaser'
import ProductNavigation from '@/components/channel/boxes/box/product-navigation'
import ProductNavigationFilter from '@/components/channel/boxes/box/product-navigation-filter'
import ElearningBox from '@/components/channel/boxes/box/elearning-box'
import BooksTeaser from '@/components/channel/boxes/box/books-teaser'

export default {
  components: {
    HeroSlider,
    DeineApothekeSlider,
    QuoteBox,
    NewsTeaser,
    ProductTeaser,
    PapersBox,
    ItemTeaser,
    ProductNavigation,
    ProductNavigationFilter,
    ElearningBox,
    BooksTeaser,
  },
  props: {
    channels: {
      type: Array,
      required: true,
    },
    paperItems: {
      type: Array,
      required: true,
    },
    shopItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    sanitizeBoxTemplate(templateAlias) {
      let name = ''
      if (templateAlias.includes('ContentBox')) {
        name = templateAlias.replace(/cms\.apoverlag\._boxes\.ContentBox\./, '')
      } else if (templateAlias.includes('Box')) {
        name = templateAlias.replace(/cms\.apoverlag\._boxes\.Box\./, '')
      }
      if (name === 'quote' || name === 'papers') {
        return name + '-box'
      } else {
        return name
      }
    },
  },
}
</script>
