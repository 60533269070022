<template>
  <GridRow v-if="collection">
    <div class="hero-slider">
      <button :id="`slider-button-prev-${box.id}`" class="hero-slider__button hero-slider__button--prev">
        <IconSliderArrow />
      </button>
      <button :id="`slider-button-next-${box.id}`" class="hero-slider__button hero-slider__button--next">
        <IconSliderArrow />
      </button>
      <div v-swiper:heroSwiper="swiperOptions">
        <div class="swiper-wrapper">
          <HeroTeaser
            v-for="(article, index) in collection.relationships.articles"
            :key="index"
            :headline-level="1"
            class="swiper-slide"
            :article="article"
            :mobileHeight="155"
          />
        </div>
        <div :id="`swiper-pagination-${box.id}`" slot="pagination" class="swiper-pagination" />
      </div>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import HeroTeaser from '@/components/ui/teaser/hero'

import IconSliderArrow from '@/components/ui/icons/icon-slider-arrow.svg'

export default {
  components: {
    GridRow,
    IconSliderArrow,
    HeroTeaser,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: `#slider-button-next-${this.box.id}`,
          prevEl: `#slider-button-prev-${this.box.id}`,
        },
        pagination: {
          el: `#swiper-pagination-${this.box.id}`,
          clickable: true,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        a11y: true,
        keyboardControl: false,
        centeredSlides: false,
        allowTouchMove: true,
        simulateTouch: true,
        preloadImages: false,
        lazy: true,
        loop: true,
      },
    }
  },
  computed: {
    collection() {
      if (this.box.attributes && this.box.attributes.templateOptions) {
        return this.box.attributes.templateOptions.collection
      }
      return null
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.hero-slider {
  position: relative;
  width: 100%;
  max-width: $content-max-width;
  overflow: hidden;
  margin: 0 auto;

  .swiper-container {
    width: 100%;
  }

  .swiper-wrapper {
    width: 100%;
  }

  &__button {
    padding: 30px;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: none;
    margin: auto;

    &:active,
    &:focus {
      background: none;
    }

    svg {
      -webkit-filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2));
      filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2));
    }

    &.swiper-button-disabled {
      display: none;
    }

    &--prev {
      left: 0;

      svg {
        transform: rotate(180deg);
        -webkit-filter: drop-shadow(1px -2px 2px rgba(0, 0, 0, 0.2));
        filter: drop-shadow(1px -2px 2px rgba(0, 0, 0, 0.2));
      }
    }

    &--next {
      right: 0;
    }
  }

  .swiper-pagination {
    display: block;

    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.4);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: $color-white;
      }
    }
  }
}
</style>
