<template>
  <article class="item-teaser">
    <picture v-if="item.Artikelbild" class="item-teaser__picture">
      <img class="item-teaser__image" :src="item.Artikelbild" />
    </picture>
    <div class="item-teaser__container">
      <span v-if="item.Kategorie" class="item-teaser__category">
        {{ item.Kategorie }}
      </span>
      <SubHeadline v-if="item.Artikelname" tag="h3" class="item-teaser__title">
        <a v-if="item.URL" :href="item.URL" class="item-teaser__link">
          {{ item.Artikelname }}
        </a>
        <template v-else>
          {{ item.Artikelname }}
        </template>
      </SubHeadline>
      <span v-if="item.Preis" class="item-teaser__price">
        {{ item.Preis }}
        <!-- 38,90 € <span class="item-teaser__price-info">netto</span> -->
      </span>
    </div>
  </article>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'

export default {
  components: {
    SubHeadline,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.item-teaser {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    height: auto;

    @media all and (min-width: $screen-width-767) {
      max-width: 200px;
      height: 300px;
    }
  }

  &__image {
    width: 100%;
  }

  &__category {
    font-size: 16px;
    line-height: 20px;
    color: $color-text-gray;
    margin-bottom: 10px;
    width: 100%;
    display: block;
    text-align: center;

    @media all and (min-width: $screen-width-767) {
      font-size: 14px;
    }
  }

  &__price {
    font-weight: 800;
    font-variation-settings: 'wght' 800;
    color: $color-red;
    width: 100%;
    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 120%;

    @media all and (min-width: $screen-width-767) {
    }

    // &-info {
    //   font-weight: 400;
    //   font-variation-settings: "wght" 400;
    //   color: $color-text-gray;
    //   font-size: 14px;
    //
    //   @media all and (min-width: $screen-width-767) {}
    // }
  }

  &__title {
    font-size: 18px;
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    line-height: 24px;
    margin: 0 0 10px 0;
    text-align: center;
    width: 100%;

    @media all and (min-width: $screen-width-767) {
      font-size: 18px;
    }
  }

  &__link {
    color: $color-text;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &:hover {
    .item-teaser__link {
      text-decoration: underline;
    }
  }

  &__text {
    font-size: 16px;
    color: $color-text-gray;
  }

  &__container {
    width: 100%;
  }
}
</style>
